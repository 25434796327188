* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body,
input,
button {
	font-family: "Kumbh Sans", cursive;
}

.contentRoute {
	display: flex;
	align-items: center;
	justify-content: center;
}

h2 {
	color: #fff;
	background-color: #ff396c;
	padding: 50px;
	text-align: center;
	text-transform: capitalize;
	margin-bottom: 10px;
}

.imgModal {
	max-width: 90%;
	max-height: 90%;
}

.ReactModal__Content {
	text-align: center;
}

.nenhumRegistro {
	color: #555;
	margin: 10px;
}

.nenhumRegistro p {
	margin: 5px;
}

/* detalhes do pedido deve exibir o modal acima do header*/
.ReactModal__Overlay {
	/* z-index: 2; */
	z-index: 100;
}

.ReactModal__Content {
	padding: 20px 0 0 !important;
	top: 20px !important;
	bottom: 20px !important;
	left: 20px !important;
	right: 20px !important;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.ReactModal__Content button {
	background-color: #ff396c;
	border: 0;
	color: #fff;
	padding: 5px;
	margin: 15px 0;
	cursor: pointer;
	text-decoration: none;
}

.MuiInputBase-formControl,
.MuiOutlinedInput-multiline {
	width: 100%;
}

@media all and (max-width: 650px) {
	form {
		width: 90%;
	}
}
