.carrinhoCompras > div > button {
	margin: 10px;
}

.carrinhoCompras > div:first-child {
	text-align: center;
}

.carrinhoCompras {
	width: 100%;
	max-width: 1000px;
	text-align: left;
	padding: 10px;
}

.etapa1 ul {
	list-style-type: none;
	padding: 10px;
}

.etapa1 .total {
	float: right;
	font-weight: bold;
	padding: 10px;
	margin-right: 25px;
}

.etapa1 .card {
	display: flex;
	flex-direction: row;
}

.etapa1 .card .esquerda {
	flex: 1;
}

.etapa1 .smallBtn:first-child {
	margin-left: 10px;
}

.etapa1 .smallBtn {
	padding: 0 10px;
	min-width: auto;
}

.etapa1 .card .esquerda .qtd {
	padding: 0 10px;
}

.etapa1 .card .direita {
	text-align: right;
}

.etapa1 .card .remover {
	padding: 3px 8px;
	margin-top: 30px;
	background-color: red;
	color: white;
}

.etapa1 .card img {
	float: left;
	width: auto;
	height: 50px;
	margin: 0 10px 0 0;
	border-radius: 5px;
}

.etapa1 .card {
	padding: 15px;
}

.etapa1 .card h4 a {
	text-decoration: none;
	color: purple;
}

.etapa1 .btnNext {
	float: right;
	margin-right: 10px;
	margin-bottom: 10px;
}

.etapa2 {
	margin-top: 10px;
}

.etapa2 .boxRodape {
	margin-top: 10px;
	display: flex;
	justify-content: space-between;
}

.etapa2 .linha {
	display: flex;
	flex-direction: row;
}

.etapa2 .coluna {
	margin: 10px;
	display: flex;
	flex-direction: column;
	width: 100%;
}

.etapa3 {
	margin-top: 10px;
}

.etapa3 fieldset {
	padding: 10px;
	width: 100%;
}

@media (max-width: 800px) {
	.etapa2 {
		margin: 20px;
	}

	.carrinhoCompras > div > button {
		margin: 5px;
	}
}

@media (max-width: 600px) {
	.carrinhoCompras > div > button {
		margin: 2px;
	}

	.etapa2 .linha {
		flex-direction: column;
	}

	.etapa1 .card {
		flex-direction: column;
	}

	.etapa1 .card .direita {
		text-align: center;
	}
}
